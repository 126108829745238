/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

import { Listeners } from '@/types';

export function walk(rootElement: Element, callback: (element: Element) => void) {
  callback(rootElement);
  let element = rootElement.firstElementChild;
  while (element) {
    walk(element, callback);
    if (element) {
      element = element.nextElementSibling;
    }
  }
}

export function isElement(target: EventTarget | null): target is Element {
  if (target) {
    return ('hasAttribute' in target) && ('getAttribute' in target);
  }
  return false;
}

export function isForm(target: EventTarget | null): target is HTMLFormElement {
  if (target) {
    return 'action' in target && ('elements' in target) && ('getAttribute' in target);
  }
  return false;
}

export class Events {
  listeners: Listeners = [];

  add(element: Element, type: string, listener: EventListener) {
    this.listeners.push({ type, element, listener });
    element.addEventListener(type, listener);
  }

  clear() {
    this.listeners.forEach((item) => {
      const { type, element, listener } = item;
      element.removeEventListener(type, listener);
    });
    this.listeners = [];
  }
}

export function mapFormToObject<T>(form: HTMLFormElement) {
  const output: Record<string, unknown> = {};
  const formData = new FormData(form);
  for (const [key, value] of formData.entries()) {
    if (value === 'on') {
      output[key] = String(true);
    } else {
      output[key] = String(value);
    }
  }
  return output as T;
}

function updateText(oldNode: Node, newNode: Node) {
  if (oldNode.textContent !== newNode.textContent) {
    oldNode.textContent = newNode.textContent;
  }
}

export function updateNode(oldNode: Node, newNode: Node) {
  if (isElement(oldNode) && isElement(newNode)) {
    updateAttributes(oldNode, newNode);
  } else {
    updateText(oldNode, newNode);
  }
  if (oldNode.childNodes.length > 0 || newNode.childNodes.length > 0) {
    updateChildren(oldNode, Array.from(oldNode.childNodes), Array.from(newNode.childNodes));
  }
}

function updateAttributes(oldNode: Element, newNode: Element) {
  const oldAttributes = Array.from(oldNode.attributes);
  const newAttributes = Array.from(newNode.attributes);
  for (const oldAttribute of oldAttributes) {
    const { name, value } = oldAttribute;
    const newValue = newNode.getAttribute(name);
    if (newValue !== null) {
      if (newValue !== value) {
        oldNode.setAttribute(name, newValue);
      }
    } else {
      oldNode.removeAttribute(name);
    }
  }
  for (const newAttribute of newAttributes) {
    const { name, value } = newAttribute;
    if (oldNode.hasAttribute(name) === false) {
      oldNode.setAttribute(name, value);
    }
  }
}

function updateChildren(parent: Node, oldList: Node[], newList: Node[]) {
  const getKey = (node: Node, keysMap: Record<string, number>) => {
    const key = node.nodeName;
    if (keysMap[key] !== undefined) {
      keysMap[key] += 1;
    } else {
      keysMap[key] = 0;
    }
    return key + keysMap[key];
  };
  const oldKeysMap: Record<string, number> = {};
  const newKeysMap: Record<string, number> = {};
  const oldPositions: Record<string, number> = {};
  const newPositions: Record<string, number> = {};
  const oldKeys: (string | null)[] = [];
  const newKeys: string[] = [];
  for (let i = 0; i < oldList.length; i += 1) {
    const key = getKey(oldList[i], oldKeysMap);
    oldKeys[i] = key;
    oldPositions[key] = i;
  }
  for (let i = 0; i < newList.length; i += 1) {
    const key = getKey(newList[i], newKeysMap);
    newKeys[i] = key;
    newPositions[key] = i;
  }
  for (let i = 0, j = 0; i < oldKeys.length || j < newKeys.length;) {
    const oldKey = oldKeys[i];
    const newKey = newKeys[j];
    if (oldKey === null) {
      i += 1;
    } else if (newKeys.length <= j) {
      parent.removeChild(oldList[i]);
      i += 1;
    } else if (oldKeys.length <= i) {
      parent.insertBefore(newList[j], oldList[i]);
      j += 1;
    } else if (oldKey === newKey) {
      updateNode(oldList[i], newList[j]);
      i += 1;
      j += 1;
    } else {
      const newPosition = newPositions[oldKey];
      const oldPosition = oldPositions[newKey];
      if (newPosition === undefined) {
        parent.removeChild(oldList[i]);
        i += 1;
      } else if (oldPosition === undefined) {
        parent.insertBefore(newList[j], oldList[i]);
        j += 1;
      } else {
        parent.insertBefore(oldList[oldPosition], oldList[i]);
        oldKeys[oldPosition] = null;
        if (oldPosition > i + 1) i += 1;
        j += 1;
      }
    }
  }
}
