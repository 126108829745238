/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/prefer-default-export */
import { BaseComponent, Component } from '@/components/BaseComponent';
import { PageTemplate } from '@/helpers/pageTemplate';
import { CreateElement } from 'vue';
import { FrameAction, PageTemplatesData } from '@/types';
import { FrameActions } from '@/helpers/frameActions';
import Frame from '@/components/Frame.vue';

export { Component } from 'vue-property-decorator';

@Component({
  components: {
    Frame,
  },
})
export class ClientTemplate<PageType extends keyof PageTemplatesData> extends BaseComponent {
  actions = new FrameActions();

  pageTemplate = new PageTemplate();

  get templateError() {
    if (this.error) {
      const { title, messages } = this.i18n.translateError(this.error);
      return {
        title,
        messages: messages.map((text) => ({ text })),
      };
    }
    return undefined;
  }

  get templateCss() {
    return this.pageTemplate.get('css');
  }

  get clientBackLink() {
    const data = this.authorization.getClientData();
    if (data) {
      return data.client_back_uri;
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  build(): readonly [PageType, PageTemplatesData[PageType]] {
    throw new Error('Method not implemented.');
  }

  renderTemplate() {
    let content = '';
    const [page, data] = this.build();
    data.error = this.templateError;
    const chainLink = this.session.getStartData()?.chain_site_uri;
    if (chainLink) {
      data.chainLink = this.actions.create(() => {
        window.location.assign(chainLink);
      });
    }
    if (this.clientBackLink) {
      data.backLink = this.actions.create(() => {
        if (this.clientBackLink) {
          window.location.assign(this.clientBackLink);
        }
      });
    }
    try {
      content = this.pageTemplate.render(page, data);
    } catch (e) {
      let messages: string[] = [];
      if (e instanceof Error) {
        messages = [e.message];
      }
      this.router.showError({
        title: 'Template Error',
        messages,
      });
    }
    return content;
  }

  render(h: CreateElement) {
    this.actions.clear();
    return h(Frame, {
      props: {
        styles: this.templateCss,
        content: this.renderTemplate(),
      },
      on: {
        action: (action: FrameAction) => {
          this.actions.call(action);
        },
      },
    });
  }

  beforeDestroy() {
    this.actions.clear();
  }
}
