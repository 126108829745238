import { ClientTemplate, Component } from '@/components/ClientTemplate';
import { Pages, PageTemplateError } from '@/types';

@Component
export default class ClientError extends ClientTemplate<Pages.Error> {
  created() {
    const error = this.store.getPage(Pages.Error);
    if (error) {
      this.error = error;
    } else if (this.authorization.check() === false) {
      this.error = {
        messages: ['Unauthorized'],
      };
    }
  }

  build() {
    let data: PageTemplateError;
    if (this.templateError) {
      data = this.templateError;
    } else {
      data = {
        messages: [{ text: 'Unknown error' }],
      };
    }
    return [Pages.Error, data] as const;
  }
}
