/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
/*
Source:
https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#polyfill
*/

interface FrameWindow extends Window {
    Element?: FrameElement;
}

type ElementMatchesFn = Element['matches'];

interface CrossBrowserElementMatches {
  matchesSelector: ElementMatchesFn;
  msMatchesSelector: ElementMatchesFn;
  oMatchesSelector: ElementMatchesFn;
  mozMatchesSelector: ElementMatchesFn;
}

interface FrameElement extends Element {
  prototype: Element & Partial<CrossBrowserElementMatches>;
}

export function matchesPolyfill(window: FrameWindow) {
  if (!window.Element) return;
  const proto = window.Element.prototype;
  if (!proto.matches) {
    proto.matches = proto.matchesSelector
             || proto.mozMatchesSelector
             || proto.msMatchesSelector
             || proto.oMatchesSelector
             || proto.webkitMatchesSelector
             || function (this: Element & { document?: Document }, s: string) {
               const matches = (this.document || this.ownerDocument).querySelectorAll(s);
               let i = matches.length;
               while (--i >= 0 && matches.item(i) !== this) {}
               return i > -1;
             };
  }
}
