/* eslint-disable import/prefer-default-export */
import {
  FrameActions as IFrameActions, FrameAction, FrameActionsMap,
} from '@/types';

export class FrameActions implements IFrameActions {
  constructor() {
    Object.freeze(this);
  }

  actions: FrameActionsMap = Object.create(null);

  create(callback: (element: Element) => void, id = this.getUid()) {
    this.actions[id] = callback;
    return id;
  }

  // eslint-disable-next-line class-methods-use-this
  private getUid() {
    // eslint-disable-next-line no-bitwise
    return `https://${(Math.random() * 1e18).toString(16)}`;
  }

  call(action: FrameAction) {
    const { id, element } = action;
    const callback = this.actions[id];
    if (callback) {
      callback(element);
    }
  }

  clear() {
    Object.keys(this.actions).forEach((key) => {
      delete this.actions[key];
    });
  }
}
